import "../PagesCss/Default.css";
// import React from 'react';
import React, { useState } from "react";
import svg1 from "../assets/img/illustrations/browser-stats.svg";
import svg2 from "../assets/img/illustrations/processing.svg";
import svg3 from "../assets/img/illustrations/windows.svg";
import svg4 from "../assets/img/illustrations/team-spirit.svg";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Dashboard</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="activity" />
                        </div>
                        Dashboard
                      </h1>
                      <div className="page-header-subtitle">
                        Set your rates, create a new quote or manage your
                        account settings from here.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="row">
                <div className="col-xl-4 mb-4">
                  {/* Dashboard example card 1*/}
                  <a
                    className="card lift h-100"
                    onClick={() => navigate("/setyourrates")}
                  >
                    <div className="card-body d-flex justify-content-center flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="me-3">
                          <i
                            className="feather-xl text-primary mb-3"
                            data-feather="package"
                          />
                          <h5>Set Your Rates</h5>
                          <div className="text-muted small">
                            Before creating a quote you need to set your rates
                            here.
                          </div>
                        </div>
                        <img src={svg1} alt="..." style={{ width: "8rem" }} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 mb-4">
                  {/* Dashboard example card 2*/}
                  <a
                    className="card lift h-100"
                    onClick={() => navigate("/createaquote")}
                  >
                    <div className="card-body d-flex justify-content-center flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="me-3">
                          <i
                            className="feather-xl text-secondary mb-3"
                            data-feather="book"
                          />
                          <h5>Create A Quote</h5>
                          <div className="text-muted small">
                            Create a new quote or manage previous quotes from
                            here.
                          </div>
                        </div>
                        <img src={svg2} alt="..." style={{ width: "8rem" }} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 mb-4">
                  {/* Dashboard example card 3*/}
                  <a
                    className="card lift h-100"
                    onClick={() => navigate("/manageaccount")}
                  >
                    <div className="card-body d-flex justify-content-center flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="me-3">
                          <i
                            className="feather-xl text-green mb-3"
                            data-feather="tool"
                          />
                          <h5>Manage Account</h5>
                          <div className="text-muted small">
                            Update your account settings from here.
                          </div>
                        </div>
                        <img src={svg3} alt="..." style={{ width: "8rem" }} />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              {/* Illustration dashboard card example*/}
              <div className="card">
                <div className="card-body text-center p-5">
                  <img
                    className="img-fluid mb-4"
                    src={svg4}
                    alt=""
                    style={{ maxWidth: "16.25rem" }}
                  />
                  <h5>Support</h5>
                  <p className="mb-4">
                    If you have any questions, you can contact us at any time.
                    Use our contact form by clicking the button below.
                  </p>
                  <a
                    className="btn btn-primary p-3"
                    onClick={() => navigate("/contactus")}
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
