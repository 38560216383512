import "../PagesCss/Default.css";
import MaterialTable from "material-table";

import { useNavigate } from "react-router";
import favicon from "../assets/img/favicon.png";
import axios from "axios";
import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
// import "./Nail.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const PasswordRecovery = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/forgotPassword/put`,
        formData
      );
      NotificationManager.success(
        "Verification code sent successfully",
        "Success"
      );
      setTimeout(() => {
        navigate(`/verificationcode`);
      }, 4000);
    } catch (error) {
      console.error("Error occured while fetching theme", error);
      NotificationManager.error("Error sending code.Contact Administrator");
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <style>
        {`
          body {
            background-color: #0061f2;
          }
        `}
      </style>
      <link href="css/styles.css" rel="stylesheet" />

      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container-xl px-4">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  {/* Basic forgot password form*/}
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header justify-content-center">
                      <h3 className="fw-light my-4">Password Recovery</h3>
                    </div>
                    <div className="card-body">
                      <div className="small mb-3 text-muted">
                        Enter your email address and we will send you a link to
                        reset your password.
                      </div>
                      {/* Forgot password form*/}
                      <form onSubmit={handleSubmit}>
                        {/* Form Group (email address)*/}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Email
                          </label>
                          <input
                            className="form-control"
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (submit options)*/}
                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <a className="small" href="/login">
                            Return to login
                          </a>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center">
                      <div className="small">
                        <a href="/register">Need an account? Sign up!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id="layoutAuthentication_footer">
          <footer className="footer-admin mt-auto footer-dark">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
