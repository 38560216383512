import "../PagesCss/Default.css";
import React, { useState, useEffect } from "react";
import favicon from "../assets/img/favicon.png";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import MaterialTable from "material-table";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ThemeProvider, createTheme } from "@mui/material";
import { Margin } from "@mui/icons-material";
const Admindashboard = (props) => {
  const defaultMaterialTheme = createTheme();
  const [supplier, setsupplier] = useState([]);
  const [alluser, setalluser] = useState([]);
  const [allsupplier, setallsupplier] = useState([]);
  const [qoute, setQoutes] = useState([]);

  const [qouteId, setQouteId] = useState(0);
  // const [formData, setFormData] = useState({
  //     nailSize:'',
  //     costPerUnit:'',

  // });
  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [id]: value,
  //   }));
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form data:', formData);
  // };
  const columnsSupplier = [
    { title: "Supplier Name", field: "nameofSupplier" },
    { title: "Client Address", field: "address" },
    {
      title: "Actions",
      field: "actions",
      render: (rowData) => (
        <div>
          <button
            onClick={() => {
              console.log("rowdata1212", rowData, rowData.tableData.id);
              const base = "Materials_Quotation.pdf?quoteid=" + rowData.quoteId;
              const jasper_username = process.env.REACT_APP_JASPER_USERNAME;
              const jasper_password = process.env.REACT_APP_JASPER_PASSWORD;
              const jasper_url = process.env.REACT_APP_JASPER_URL;
              console.log("JasperURL: ", jasper_url);

              return new Promise((resolve, reject) => {
                axios
                  .get(`${jasper_url}${base}`, {
                    responseType: "blob",
                    headers: {
                      Accept: "application/pdf",
                    },
                    auth: {
                      username: jasper_username,
                      password: jasper_password,
                    },
                  })
                  .then((response) => {
                    resolve(response);
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
                    const objectUrl = window.URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.href = objectUrl;
                    link.download = "clientQuote.pdf";
                    link.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(objectUrl);
                    }, 100);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            }}
            className="btn btn-primary p-2 mr-2"
            style={{ marginRight: "10px" }}
          >
            View Quote
          </button>
        </div>
      ),
    },
  ];
  const columnsCustomer = [
    { title: "Customer Name", field: "clientName" },
    { title: "Client Address", field: "address" },
    {
      title: "Actions",
      field: "actions",
      render: (rowData) => (
        <div>
          <button
            onClick={() => {
              console.log("rowdata1212", rowData, rowData.tableData.id);
              const base = "Customer_Quotation.pdf?P_quoteid=" + rowData.quoteId;
              const jasper_username = process.env.REACT_APP_JASPER_USERNAME;
              const jasper_password = process.env.REACT_APP_JASPER_PASSWORD;
              const jasper_url = process.env.REACT_APP_JASPER_URL;
              console.log("JasperURL: ", jasper_url);

              return new Promise((resolve, reject) => {
                axios
                  .get(`${jasper_url}${base}`, {
                    responseType: "blob",
                    headers: {
                      Accept: "application/pdf",
                    },
                    auth: {
                      username: jasper_username,
                      password: jasper_password,
                    },
                  })
                  .then((response) => {
                    resolve(response);
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
                    const objectUrl = window.URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.href = objectUrl;
                    link.download = "clientQuote.pdf";
                    link.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(objectUrl);
                    }, 100);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            }}
            className="btn btn-primary p-2 mr-2"
            style={{ marginRight: "10px" }}
          >
            View Quote
          </button>
        </div>
      ),
    },
  ];
  const [columns, setColumns] = useState(columnsSupplier);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/Home/getSupplierAndCustomer`
        );

        setsupplier(response.data);

        const Alluserresponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/Home/getusersadmin`
        );

        const AllSupplierresponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/Home/getquotesadmin`
        );

        setsupplier(response.data);
        setalluser(Alluserresponse.data);
        setallsupplier(AllSupplierresponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.info("Error fetching  data.Contact Administrator.");
      }
      // try {
      //   const response = await axios.get(
      //     `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getQoutes`
      //   );
      //   console.log("Qoutes3", response.data);
      //   setQoutes(response.data);
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      //   // NotificationManager.info("Error fetching rate data:", error);
      // }
    };

    fetchData();
  }, []);
  console.log("CustomerQuotes699",supplier)
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="activity" />
                        </div>
                        Admin Dashboard
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                <div className="card-header">Statistics</div>
                <div className="card-body">
                  {/* Example Colored Cards for Dashboard Demo*/}
                  <div className="row">
                    <div className="col-lg-6 col-xl-4 mb-4">
                      <div className="card bg-primary text-white h-100">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="me-3">
                              <div className="text-white-75 small">
                                Total Users
                              </div>
                              <div className="text-lg fw-bold">
                                {alluser.length}
                              </div>
                            </div>
                            <i
                              className="feather-xl text-white-50"
                              data-feather="users"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setColumns(columnsCustomer);
                      }}
                      className="col-lg-6 col-xl-4 mb-4 admindash"
                    >
                      <div className="card bg-warning text-white h-100">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="me-3">
                              <div className="text-white-75 small">
                                Customer Quotes Created
                              </div>
                              <div className="text-lg fw-bold">
                                {allsupplier.length}
                              </div>
                            </div>
                            <i
                              className="feather-xl text-white-50"
                              data-feather="dollar-sign"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-4 mb-4 admindash"
                      onClick={() => {
                        setColumns(columnsSupplier);
                      }}
                    >
                      <div className="card bg-success text-white h-100">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="me-3">
                              <div className="text-white-75 small">
                                Material Quotes Created
                              </div>
                              <div className="text-lg fw-bold">
                                {" "}
                                {allsupplier.length}
                              </div>
                            </div>
                            <i
                              className="feather-xl text-white-50"
                              data-feather="check-square"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Example DataTable for Dashboard Demo*/}
              <div className="card mb-4">
                <div className="card-header">All Material Quotes&nbsp;</div>
                <div className="card-body">
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                      title="Material Details"
                      columns={columns}
                      data={supplier}
                      options={{
                        search: true,
                        sorting: true,
                        paging: true,
                        actionsColumnIndex: -1,
                      }}
                      // actions={[
                      //   {
                      //     icon: () => <Edit />,
                      //     tooltip: "Edit/Display",
                      //     onClick: (event, rowData) => {
                      //       console.log("MaterialTable Row Data:", rowData);
                      //       // Handle edit action
                      //       handleEdit(event, rowData);
                      //     },
                      //   },
                      // ]}
                      // editable={{
                      //   onRowDelete: (oldData) =>
                      //     new Promise((resolve) => {
                      //       // Display a confirmation dialog
                      //       // const shouldDelete = window.confirm('Are you sure you want to delete this nail?');

                      //       // Handle delete action
                      //       handleDelete(oldData);
                      //       resolve();
                      //     }),
                      // }}
                    />
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Quote
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              Are you sure you wish to delete this quote?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button className="btn btn-danger" type="button">
                Delete Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admindashboard;
