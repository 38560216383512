import "../PagesCss/Default.css";
// import React from 'react';
import React, { useState } from "react";
import logo from "../assets/img/logo/logo2.png";
import axios from "axios";
import { useNavigate } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const Login = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
        formData
      );

      // Assuming 'response.data' contains some user-related data or token
      const userData = response.data;

      // Store the user data or token in local storage
      localStorage.setItem("userData", JSON.stringify(userData));

      console.log("User logged in successfully:", userData);

      // Redirect to the home page or any other route
      window.location = "/home";
    } catch (error) {
      console.error("Error occurred while logging in", error);

      if (error.response) {
        // alert(`Error!!! ${error.response.data}`);
        NotificationManager.error(`Error4!!! ${error.response.data}`);
      } else {
        NotificationManager.error(`Login Failed !! Contact Administrator `);
      }
    }
  };
  const currentYear = new Date().getFullYear();

  return (
    <>
      <style>
        {`
          body {
            background-color: #0061f2;
          }
          
        `}
      </style>
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main
            className="div-block"
            style={{
              display: "grid",
              placeItems: "center",
              height: "100vh",
              margin: "0px",
            }}
          >
            <div className="container-xl px-4 div-block-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  {/* Basic login form*/}
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div
                      className="card-header justify-content-center"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        style={{
                          width: "20rem",
                          height: "15rem",
                          marginTop: "-69px",
                          objectFit: "cover",
                        }}
                      />
                      <h3
                        className="fw-bold"
                        style={{
                          fontSize: "25px",
                          marginTop: "-32px",
                          marginBottom: "10px",
                        }}
                      >
                        Login
                      </h3>
                    </div>

                    <div className="card-body">
                      {/* Login form*/}
                      <form onSubmit={handleSubmit}>
                        {/* Form Group (email address) */}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="email">
                            Email
                          </label>
                          <input
                            className="form-control"
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (password) */}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="password">
                            Password
                          </label>
                          <input
                            className="form-control"
                            id="password"
                            type="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (remember password checkbox)*/}
                        {/* <div className="mb-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="rememberPasswordCheck"
                              type="checkbox"
                              defaultValue=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberPasswordCheck"
                            >
                              Remember Password
                            </label>
                          </div>
                        </div> */}
                        {/* Form Group (login box)*/}
                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <a className="small" href="/PasswordRecovery">
                            Forgot Password?
                          </a>
                          <button type="submit" className="btn btn-primary">
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center">
                      <div className="small">
                        <a href="/register">Need an account? Sign up!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id="layoutAuthentication_footer">
          <footer className="footer-admin mt-auto footer-dark">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Login;
